.btn-user-holder {
  border: none;
  background-color: transparent;
}
.glc-dropdown-menu-title-content {
  a:hover,
  button:hover {
    color: rgb(14, 99, 183) !important;
  }
}

.logo-container {
  display: flex;
  justify-content: center;
  align-items: center;
  .logo {
    width: 40px;
    height: 40px;
    fill: currentColor;
    color: #000 !important;
    @media only screen and (max-width: 538px) {
      width: 30px;
      height: 30px;
    }
    @media only screen and (max-width: 390px) {
      width: 26px;
      height: 26px;
    }
  }
}

.pair-container {
  display: flex;
  justify-content: center;
  align-items: center;
  .pair-icon {
    font-size: 2.5rem;
    &:hover {
      color: rgb(43, 103, 216);
    }
  }
}

.flag-container {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
.flag-img {
  width: 25px;
  object-fit: cover;
}
