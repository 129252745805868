@import '~antd/dist/antd.less';
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@300;400;500;700;900&display=swap');
// Override antd theme
@import 'constants/theme.less';

body {
  font-family: 'Noto Sans JP';
}
//Layout page
#layout-root {
  min-height: 100vh;
}

#layout-header {
  position: fixed;
  z-index: 1000;
  width: 100%;
}

#layout-main-content {
  display: flex;
  width: 100%;
  flex-direction: row;
}
#layout-content {
  margin: 64px 0 1rem 1rem;
  // padding-right: 1rem;
  width: 100vw;
  overflow-y: scroll;
  // background-color: rebeccapurple;
}

#layout-sider {
  position: -webkit-sticky;
  position: sticky;
  margin-top: 64px;
  position: sticky;
  top: 64px;
  left: 0;
  z-index: 999;
  // flex: 1 1;
  height: fit-content;
}

//Table
.glc-table {
  width: 100%;
  background-color: #f0f2f5;
  @media screen and (max-width: 821px) {
    width: fit-content;
  }
  table {
    padding: 0 0.5rem;
  }
}
.glc-table-tbody {
  background-color: #fafafa;
}
.glc-table table {
  border-spacing: 0 8px;
}
.glc-table-row td:first-child {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}
.glc-table-row td:last-child {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}
.table-head-container {
  display: flex;
  justify-content: space-between !important;
  align-items: center;
  flex-wrap: nowrap;
}

.action-btn {
  border: none;
  cursor: pointer;
  background-color: transparent;
  &:hover {
    color: cyan;
  }
}
@media screen and (max-width: 900px) {
  .page-header-container {
    position: sticky;
    left: 0;
  }
  .glc-table-wrapper {
    position: relative;
    margin-right: 1rem;
    overflow: scroll;
  }
  // .glc-pagination {
  //   position: fixed;
  //   bottom: 64px;
  //   right: 1rem;
  // }
  .glc-card {
    margin-right: 1rem;
  }
}
.search-container {
  display: flex;
  align-items: center;
  gap: 2rem;
}

@media screen and (max-width: 700px) {
  .glc-table {
    width: auto;
    margin-right: 1rem !important;
    table {
      width: 100%;
      thead {
        display: none;
      }
      tbody,
      tr,
      td {
        display: block;
        width: 100%;
      }
      tr {
        margin-bottom: 1rem;
        border-bottom: 1px solid #a6b727;
      }
      td {
        text-align: right;
        padding-left: 50%;
        text-align: right;
        position: relative;
      }
      .glc-table-cell::before {
        position: absolute;
        left: 0;
        width: 50%;
        padding-left: 15px;
        font-size: 15px;
        font-weight: bold;
        text-align: left;
      }
    }
  }
  .search-container {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 0;
  }
}
.title__head {
  font-weight: 500;
}

.item-center {
  align-items: center;
}
